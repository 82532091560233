import logo from './logo.svg';
import './App.css';

import Home from './pages/Home';
import Navbar from './components/Navbar';
import NavbarMobile from './components/NavbarMobile';

function App() {
  return (
    <div className="App">
      <div className='app-navbar'>
        <Navbar></Navbar>
      </div>
      <div className='app-navbar-mobile'>
        <NavbarMobile></NavbarMobile>
      </div>
      <Home></Home>
    </div>
  );
}

export default App;
