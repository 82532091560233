import React from 'react'
import '../styles/ChangeCountry.css'

import cross from '../svgs/cross.svg'

import qatar from '../flags/qa.svg'
import uae from '../flags/ae.svg'
import nigeria from '../flags/ng.svg'

export default function ChangeCountry( props ) {
  return (
    <div className='change-country-root'>
        <div className='change-country-header'>
            <h2>Change Country</h2>
            <img src={cross} alt="Close menu" onClick={() => props.setChangeCountry(false)}></img>
        </div>
        <ul className='change-country-list'>
            <li className='change-country-item change-country-selected'>
                <div className='change-country-flag' style={{backgroundImage: `url(${qatar})`}}></div>
                <h3>Qatar</h3>
            </li>
            <li className='change-country-item'>
                <div className='change-country-flag' style={{backgroundImage: `url(${uae})`}}></div>
                <h3>UAE</h3>
            </li>
            <li className='change-country-item'>
                <div className='change-country-flag' style={{backgroundImage: `url(${nigeria})`}}></div>
                <h3>Nigeria</h3>
            </li>
        </ul>
    </div>
  )
}
