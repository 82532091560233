class Clinic {
    constructor(name, hearts, picture, specialities, area, address) {
        this.name = name;
        this.hearts = hearts;
        this.picture = picture;
        this.specialities = specialities;
        this.area = area;
        this.address = address;
    }
}

export default Clinic