import React from 'react'
import '../styles/InsuranceProfile.css'

export default function InsuranceProfile( props ) {

    const renderPlans = () => {
        return props.insurance.plans.map((plan) => {
            return <li className='insurance-profile-plan'>{plan}</li>
        })
    }

  return (
    <div className='insurance-profile-root'>
        <div className='insurance-profile-header'>
            <h5 className='insurance-profile-name'>{props.insurance.name}</h5>
            <img className='insurance-profile-picture' src={require("../images/insurance/"+props.insurance.picture)} alt='Insurace icon'></img>
        </div>
        <div className='insurance-profile-plans-container'>
            <ul className='insurance-profile-plans'>
                {renderPlans()}
                <li className='insurance-profile-plan show-more'>Show more</li>
            </ul>
        </div>
    </div>
  )
}
