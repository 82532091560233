class Profile {
    constructor(popular, videoVisit, hearts, picture, name, profession, language, clinicName, clinicLink, clinicCity, startingPrice) {
        this.popular = popular;
        this.videoVisit = videoVisit;
        this.hearts = hearts;
        this.picture = picture;
        this.name = name;
        this.profession = profession;
        this.language = language;
        this.clinic = {name: clinicName, link: clinicLink, city: clinicCity};
        this.startingPrice = startingPrice;
    }
}

export default Profile