import React from 'react'
import '../styles/Searchbar.css'
import search from '../svgs/search.svg'

export default function Searchbar() {
  return (
    <div className='searchbar-root'>
        <div className='searchbar-icon'>
            <img src={search} />
        </div>
        <div className='searchbar-input-container'>
            <form className='searchbar-input-form'>
                <input className='searchbar-input-box' placeholder='Search doctors, procedures, clinics'/>
            </form>
        </div>
    </div>
  )
}
