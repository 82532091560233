import React from 'react'
import qatar from '../flags/qa.svg'
import uae from '../flags/ae.svg'
import nigeria from '../flags/ng.svg'
import '../styles/LocationPicker.css'

export default function LocationPicker() {
  return (
    <div className='locationpicker-root'>
        <div className="dropdown locationpicker-dropdown">
            <button className="dropdown-toggle navbar-specialties-link navbar-dropdown locationpicker-dropdown-button" type='button' data-bs-toggle="dropdown" aria-expanded="false">
                <div className='locationpicker-flag' style={{backgroundImage: `url(${qatar})`}}></div>
            </button>
            <ul className="dropdown-menu navbar-region-menu">
                <li className='locationpicker-menu-item'><img className='locationpicker-item-flag' src={qatar} /> <a className="dropdown-item locationpicker-item-text" href="#">Qatar</a></li>
                <li className='locationpicker-menu-item'><img className='locationpicker-item-flag' src={uae} /> <a className="dropdown-item locationpicker-item-text" href="#">UAE</a></li>
                <li className='locationpicker-menu-item'><img className='locationpicker-item-flag' src={nigeria} /> <a className="dropdown-item locationpicker-item-text" href="#">Nigeria</a></li>
            </ul>
        </div>
    </div>
  )
}
