import React from 'react'
import '../styles/Navbar.css'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap/dist/js/bootstrap'
import Searchbar from './Searchbar'
import LocationPicker from './LocationPicker'
import HeliumDocLogo from './HeliumDocLogo'

export default function Navbar() {
  return (
    <nav className='navbar-root'>
        <section className='navbar-container'>
            <div className='navbar-left'>
                <div className='navbar-header'>
                    <HeliumDocLogo></HeliumDocLogo>
                </div>
                <div className='navbar-content'>
                    <ul className='navbar-content-list'>
                        <li className='navbar-specialities'>
                            {/* <a className='navbar-specialties-link'>
                                Specialities
                            </a> */}
                            <div className="dropdown">
                                <a className="dropdown-toggle navbar-specialties-link" href="#" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                                    Specialities
                                </a>
                            
                                <div className="dropdown-menu">
                                    <div className="navbar-specialities-dropdown">
                                        <div className='navbar-general-care'>
                                            General Care
                                            <ul className='navbar-specialities-itemlist'>
                                                <li><a className="dropdown-item" href="#">General Practitioners (GP)</a></li>
                                                <li><a className="dropdown-item" href="#">Internal Medicine</a></li>
                                                <li><a className="dropdown-item" href="#">Pediatricians</a></li>
                                                <li><a className="dropdown-item" href="#">Obstetricians & Gynecologists (OB/GYN)</a></li>
                                                <li><a className="dropdown-item" href="#">General Dentists  </a></li>
                                            </ul>
                                        </div>
                                        <div className='navbar-sepcialized-care'>
                                            Specalized Care
                                            <ul className='navbar-specialities-itemlist'>
                                                <li><a className="dropdown-item" href="#">Orthopedic Surgeons</a></li>
                                                <li><a className="dropdown-item" href="#">Urologists</a></li>
                                                <li><a className="dropdown-item" href="#">Eye Doctors</a></li>
                                                <li><a className="dropdown-item" href="#">Ear, Nose & Throat (ENT)</a></li>
                                                <li><a className="dropdown-item" href="#">Endocrinologist</a></li>
                                                <li><a className="dropdown-item" href="#">Dermatologists</a></li>
                                            </ul>
                                        </div>
                                        <div className='navbar-other-care'>
                                            Other Care
                                            <ul className='navbar-specialities-itemlist'>
                                                <li><a className="dropdown-item" href="#">Plastic Surgeons</a></li>
                                                <li><a className="dropdown-item" href="#">Psychiatrists</a></li>
                                                <li><a className="dropdown-item" href="#">Orthodontists</a></li>
                                            </ul>
                                        </div>
                                    </div>
                                    
                                    {/* <li>Hello there</li> */}
                                    {/* <li><a className="dropdown-item" href="#">Something else here</a></li> */}
                                </div>
                            </div>
                        </li>
                        <li className='navbar-fordoctors'>
                            For Doctors
                        </li>
                        <li className='navbar-search'>
                            <Searchbar></Searchbar>
                        </li>
                    </ul>
                </div>
            </div>
            <div className='navbar-right'>
                <ul className='navbar-content-list'>
                    <li className='navbar-login'>
                        Log in
                    </li>
                    <li className='navbar-signup'>
                        <a className='navbar-signup-link'>
                            Sign up
                        </a>
                    </li>
                   <LocationPicker></LocationPicker>
                    <li className='navbar-arabic'>
                        <a className='navbar-arabic-link' href="/ar/" target="_self" id="changeToArabic">ع</a>
                    </li>
                </ul>
            </div>
        </section>
    </nav>
  )
}
