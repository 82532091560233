import React, { useState } from 'react'
import '../styles/NavbarMobile.css'
import HeliumDocLogo from './HeliumDocLogo'
import search from '../svgs/search.svg'
import qatar from '../flags/qa.svg'
import uae from '../flags/ae.svg'
import nigeria from '../flags/ng.svg'
import Menu from './Menu'
import SearchMenu from './SearchMenu'
import ChangeCountry from './ChangeCountry'

export default function NavbarMobile() {
    const [showMenu, setShowMenu] = useState(false);
    const [showSearch, setShowSearch] = useState(false);
    const [showChangeCountry, setChangeCountry] = useState(false);

    return (
        <div className='navbar-mobile-root'>
            <div className={'navbar-mobile-menu'+ (showMenu ? '-shown' : '-hidden')}>
                <Menu setShowMenu={setShowMenu}/>
            </div>
            <div className={'navbar-mobile-search'+ (showSearch ? '-shown' : '-hidden')}>
                <SearchMenu setShowSearch={setShowSearch}/>
            </div>
            <div className={'navbar-mobile-country'+ (showChangeCountry ? '-shown' : '-hidden')}>
                <ChangeCountry setChangeCountry={setChangeCountry}/>
            </div>

            <div className='navbar-mobile-container'>
                <ul className='navbar-mobile-left'>
                    <li>
                        <button className='navbar-mobile-menu-button' onClick={() => setShowMenu(true)}>
                            <div className='navbar-mobile-menu-button-stripe'></div>
                            <div className='navbar-mobile-menu-button-stripe'></div>
                            <div className='navbar-mobile-menu-button-stripe'></div>
                        </button>
                    </li>
                    <li>
                        <HeliumDocLogo></HeliumDocLogo>
                    </li>
                </ul>
                <ul className='navbar-mobile-right'>
                    <li className='navbar-mobile-to-arabic'>
                        <a className='navbar-mobile-arabic-link' href="/ar/" target="_self" id="changeToArabic">ع</a>
                    </li>
                    <li className='navbar-mobile-search' onClick={() => setShowSearch(true)}>
                        <img src={search} />
                    </li>
                    <li className='navbar-mobile-location-picker' onClick={() => setChangeCountry(true)}>
                        <div className='navbar-mobile-locationpicker-flag' style={{backgroundImage: `url(${qatar})`}}></div>
                    </li>
                </ul>
            </div>
        </div>
    )
}
