import React from 'react'
import '../styles/SearchMenu.css'

export default function SearchMenu( props ) {
  return (
    <div className='search-menu-root'>
        <div className='search-menu-top-bar'>
            <div className='search-menu-search-bar'>
                <input className='search-menu-search-input' placeholder='Search doctors, procedures, clinics'></input>
            </div>
            <button className='search-menu-cancel' onClick={() => {props.setShowSearch(false)}}>
                Cancel
            </button>
        </div>
        <div className='search-menu-results'>

        </div>
    </div>
  )
}
