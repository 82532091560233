import React, { useState } from 'react'
import '../styles/Menu.css'
import arrow from '../svgs/arrow.svg'
import cross from '../svgs/cross.svg'

import facebook from '../svgs/socials/facebook.svg'
import linkedin from '../svgs/socials/linkedin.svg'
import instagram from '../svgs/socials/instagram.svg'
import twitter from '../svgs/socials/twitter.svg'


export default function Menu( props ) {
    const [menuTab, setMenuTab] = useState('main');

    const renderMenu = () => {
        if (menuTab === 'main') {
            return renderMainTab();
        }
        if (menuTab === 'specialities') {
            return renderSpecialitiesTab();
        }
        if (menuTab === 'change-country') {
            return renderChangeCountryTab();
        }
    }

    const renderMainTab = () => {
        return (
            <div className='menu-main-container'>
                <div className='menu-top'>
                    <ul className='menu-info'>
                        <li className='menu-info-item'>
                            <div className='menu-buttons'>
                                <button className='menu-signup'>
                                    Sign Up
                                </button>
                                <button className='menu-login'>
                                    Login
                                </button>
                            </div>
                            <button className='menu-close' onClick={() => props.setShowMenu(false)}> <img src={cross} alt="close menu"></img> </button>
                        </li>
                        <li className='menu-info-item' onClick={() => setMenuTab('specialities')}>
                            <h4>Specialities</h4>
                            <img src={arrow} alt="Go to information tab"></img>
                        </li>
                        <li className='menu-info-item'  onClick={() => setMenuTab('change-country')}>
                            <h4>Change Country</h4>
                            <img src={arrow} alt="Go to information tab"></img>
                        </li>
                        <li className='menu-info-item'>
                            <h4>For Doctors</h4>
                        </li>
                    </ul>
                </div>
                <div className='menu-bottom'>
                    <div className='menu-socials-container'>
                        <h3>Follow us</h3>
                        <div className='menu-socials'>
                            <ul className='menu-socials'>
                                <li className='menu-social facebook'><img src={facebook} alt='facebook social'></img></li>
                                <li className='menu-social linkedin'><img src={linkedin} alt='linkedin social'></img></li>
                                <li className='menu-social instagram'><img src={instagram} alt='instagram social'></img></li>
                                <li className='menu-social twitter'><img src={twitter} alt='twitter social'></img></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    const renderSpecialitiesTab = () => {
        return (
            <div className='menu-specialities-root'>
                <div className='menu-header'>
                    <img src={arrow} onClick={() => setMenuTab('main')}></img>
                    <h3>Specialities</h3>
                </div>
                <ul className='menu-list'>
                    <li className='menu-list-item'>
                        <button className='menu-list-item-button'>
                            <h4>General Practitioners (GP)</h4>
                        </button>
                    </li>
                    <li className='menu-list-item'>
                        <button className='menu-list-item-button'>
                            <h4>Internal Medicine</h4>
                        </button>
                    </li>
                    <li className='menu-list-item'>
                        <button className='menu-list-item-button'>
                            <h4>Pediatricians</h4>
                        </button>
                    </li>
                    <li className='menu-list-item'>
                        <button className='menu-list-item-button'>
                            <h4>Obstetricians & Gynecologists (OB/GYN)</h4>
                        </button>
                    </li>
                    <li className='menu-list-item'>
                        <button className='menu-list-item-button'>
                            <h4>General Dentists</h4>
                        </button>
                    </li>
                    <li className='menu-list-item'>
                        <button className='menu-list-item-button'>
                            <h4>Orthopedic Surgeons</h4>
                        </button>
                    </li>
                    <li className='menu-list-item'>
                        <button className='menu-list-item-button'>
                            <h4>Urologists</h4>
                        </button>
                    </li>
                    <li className='menu-list-item'>
                        <button className='menu-list-item-button'>
                            <h4>Eye Doctors</h4>
                        </button>
                    </li>
                    <li className='menu-list-item'>
                        <button className='menu-list-item-button'>
                            <h4>Ear, Nose & Throat (ENT)</h4>
                        </button>
                    </li>
                    <li className='menu-list-item'>
                        <button className='menu-list-item-button'>
                            <h4>Endocrinologist</h4>
                        </button>
                    </li>
                    <li className='menu-list-item'>
                        <button className='menu-list-item-button'>
                            <h4>Dermatologists</h4>
                        </button>
                    </li>
                    <li className='menu-list-item'>
                        <button className='menu-list-item-button'>
                            <h4>Plastic Surgeons</h4>
                        </button>
                    </li>
                    <li className='menu-list-item'>
                        <button className='menu-list-item-button'>
                            <h4>Psychiatrists</h4>
                        </button>
                    </li>
                    <li className='menu-list-item'>
                        <button className='menu-list-item-button'>
                            <h4>Orthodontists</h4>
                        </button>
                    </li>
                </ul>
            </div>
        )
    }

    const renderChangeCountryTab = () => {
        return (
            <div className='menu-change-country-root'>
                <div className='menu-header'>
                    <img src={arrow} onClick={() => setMenuTab('main')}></img>
                    <h3>Change Country</h3>
                </div>
                <ul className='menu-list'>
                    <li className='menu-list-item'>
                        <button className='menu-list-item-button'>
                            <h4>Qatar</h4>
                        </button>
                    </li>
                    <li className='menu-list-item'>
                        <button className='menu-list-item-button'>
                            <h4>Emirates</h4>
                        </button>
                    </li>
                    <li className='menu-list-item'>
                        <button className='menu-list-item-button'>
                            <h4>Nigeria</h4>
                        </button>
                    </li>
                </ul>
            </div>
        )
    }

    return (
        <div className='menu-root'>
            {renderMenu()}
        </div>
    )
}
