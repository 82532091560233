import React from 'react'
import '../styles/BlogProfile.css'

export default function BlogProfile( props ) {
  return (
    <div className='blog-profile-root'>
        <div className='blog-profile-picture-container'>
            <img className='blog-profile-picture' src={require("../images/blogs/"+props.blog.picture)}></img>
        </div>
        <div className='blog-profile-info'>
            <h4 className='blog-profile-info-header'>{props.blog.header}</h4>
            <p className='blog-profile-info-description'>{props.blog.description}</p>
            <a className='blog-profile-read-more' href={props.blog.link}>Read more</a>
        </div>
    </div>
  )
}
