import React from 'react'
import '../styles/DoctorProfile.css'
import heart from '../svgs/heart.svg'

// DoctorProfile uses [Profile] model which has the following data:

//-- popular          = true or false based on popularity of doctor;
//-- videoVisit       = true or false depending on video visit or not;
//-- hearts           = number of hearts;
//-- picture          = url of doctor's picture;
//-- name             = doctor's name;
//-- profession       = doctor's profession;
//-- language         = doctor's language(s);
//-- clinic           = {name: name of clinic, link: link to clinic site, city: name of city};
//-- startingPrice    = doctor starting price;

export default function DoctorProfile( props ) {

    // Render the hearts for the profile
    const renderHearts = () => {
        const hearts = [];
        for (let i = 0; i < props.profile.hearts; i++) { hearts.push(i); }

        return hearts.map((heartNum) => {
            return <img className='heart' src={heart}></img>
        })
    }

    // Render all tags for the profile
    const renderTags = () => {
        return (
            <>
                {props.profile.popular ? <div className='doctor-profile-tag-popular'><h3>Popular</h3></div> : <></>}
                {props.profile.videoVisit ? <div className='doctor-profile-tag-video-visit'><svg width="11" height="7" fill="none" xmlns="http://www.w3.org/2000/svg" class="sc-3b47186d-5 ftVyzu"><path fill-rule="evenodd" clip-rule="evenodd" d="M7.345 6.694a.731.731 0 0 1-.573.266H1.375a.845.845 0 0 1-.61-.266.849.849 0 0 1-.269-.622v-4.77c0-.237.086-.435.256-.596a.876.876 0 0 1 .623-.241h5.397c.228 0 .419.08.573.24.155.162.232.36.232.597v4.77a.917.917 0 0 1-.232.622Zm3.359-5.417v4.871L8.31 4.804V2.62l2.393-1.344Z" fill="#fff"></path></svg><h3>Video Visit</h3></div> : <></>}
            </>
        )
    }
  
  return (
    <div className='doctor-profile-root'>
        <div className='doctor-profile-tags'>
            {renderTags()}
        </div>
        <div className='doctor-profile-personal-details-container'>
            <img className='doctor-profile-personal-details-picture' src={require("../images/profiles/"+props.profile.picture)}></img>
            <div className='doctor-profile-personal-details'>
                <div className='doctor-profile-personal-details-hearts'>
                    {renderHearts()}
                </div>
                <h3 className='doctor-profile-personal-details-name'>
                    {props.profile.name}
                </h3>
                <h4 className='doctor-profile-personal-details-profession'>
                    {props.profile.profession}
                </h4>
            </div>
        </div>
        <ul className='doctor-profile-other-info'>
            <li className='doctor-profile-other-info-item'>
                <div className='doctor-profile-other-info-item-icon'>
                    <svg width="19" height="20" fill="none" xmlns="http://www.w3.org/2000/svg" class="sc-3b47186d-29 dliDKf"><path fill-rule="evenodd" clip-rule="evenodd" d="M5.893 8.734h6.695c.261 0 .465-.209.465-.476a.466.466 0 0 0-.465-.477H5.893a.466.466 0 0 0-.465.477c0 .267.204.476.465.476ZM5.851 11.251h6.772c.265 0 .471-.209.471-.475a.467.467 0 0 0-.47-.475H5.85a.467.467 0 0 0-.47.475c0 .266.206.475.47.475Z" fill="#555"></path><path fill-rule="evenodd" clip-rule="evenodd" d="M3.04 14.323h1.514v1.564c0 .185.103.348.264.428a.498.498 0 0 0 .494-.046l2.525-1.934h7.6c.263 0 .47-.208.47-.475V5.416a.468.468 0 0 0-.47-.475H3.038a.468.468 0 0 0-.47.475v8.432c0 .267.217.475.47.475Zm.47-8.432h11.444v7.482H7.677a.498.498 0 0 0-.287.093l-1.895 1.46v-1.078a.468.468 0 0 0-.47-.475H3.509V5.891Z" fill="#555"></path></svg>
                </div>
                <h5>{props.profile.language}</h5>
            </li>
            <li className='doctor-profile-other-info-item'>
                <div className='doctor-profile-other-info-item-icon'>
                    <svg width="13" height="14" fill="none" xmlns="http://www.w3.org/2000/svg" class="sc-3b47186d-27 cHQveb"><path fill-rule="evenodd" clip-rule="evenodd" d="M11.26 11.708H7.967V8.413a.206.206 0 0 0-.206-.206H4.877a.206.206 0 0 0-.206.206v3.295H1.376V3.883h2.83a2.247 2.247 0 0 0 4.224 0h2.83v7.825Zm-6.177 0h2.471v-3.09H5.083v3.09Zm-4.53 1.236h11.532v-.824H.552v.824Zm0-10.297H4.09c-.007.037-.01.075-.016.113-.003.026-.008.053-.01.08a2.287 2.287 0 0 0 0 .438c.002.027.007.053.01.08.005.038.009.075.016.113H.552v-.824Zm5.766-1.442A1.856 1.856 0 0 1 8.172 3.06a1.885 1.885 0 0 1-.028.312 1.853 1.853 0 1 1-1.825-2.166Zm2.227 1.442h3.539v.824H8.546c.007-.038.01-.075.016-.113.003-.027.008-.053.01-.08a2.287 2.287 0 0 0 0-.439c-.002-.026-.007-.053-.01-.08-.005-.037-.009-.075-.016-.112Zm3.745 1.236a.206.206 0 0 0 .205-.206V2.44a.206.206 0 0 0-.205-.206H8.426a2.262 2.262 0 0 0-4.215 0H.347a.206.206 0 0 0-.206.206v1.236c0 .113.092.206.206.206h.617v7.825H.347a.206.206 0 0 0-.206.206v1.235c0 .114.092.206.206.206H12.29a.206.206 0 0 0 .205-.206v-1.235a.206.206 0 0 0-.205-.206h-.618V3.883h.618Z" fill="#555"></path><path fill-rule="evenodd" clip-rule="evenodd" d="M5.289 2.853h.618a.206.206 0 0 0 .206-.206v-.618h.411v.618c0 .114.093.206.206.206h.618v.412H6.73a.206.206 0 0 0-.206.206v.617h-.411v-.617a.206.206 0 0 0-.206-.206h-.618v-.412Zm-.206.823H5.7v.618c0 .114.092.206.206.206h.823a.206.206 0 0 0 .206-.206v-.617h.618a.206.206 0 0 0 .206-.206v-.824a.206.206 0 0 0-.206-.206h-.618v-.618a.206.206 0 0 0-.206-.206h-.823a.206.206 0 0 0-.206.206v.618h-.618a.206.206 0 0 0-.206.206v.824c0 .113.092.205.206.205ZM2.611 7.386h.824V6.15H2.61v1.236Zm1.03-1.648H2.405a.206.206 0 0 0-.206.206v1.648c0 .113.092.206.206.206h1.236a.206.206 0 0 0 .206-.206V5.944a.206.206 0 0 0-.206-.206ZM9.2 7.386h.825V6.15H9.2v1.236Zm-.205.412h1.236a.206.206 0 0 0 .206-.206V5.944a.206.206 0 0 0-.206-.206H8.995a.206.206 0 0 0-.206.206v1.648c0 .113.092.206.206.206Z" fill="#555"></path></svg>
                </div>
                <a href={props.profile.clinic.link}>{props.profile.clinic.name}</a>
                <h5>({props.profile.clinic.city})</h5>
            </li>
            <li className='doctor-profile-other-info-item'>
                <div className='doctor-profile-other-info-item-icon'>
                    <svg width="8" height="15" fill="none" xmlns="http://www.w3.org/2000/svg" class="sc-3b47186d-28 riRkR"><path d="M7.463 5.555 4.708 3.143a.801.801 0 0 0-1.05 0l-.977.856a2.502 2.502 0 0 1-.087-.654c0-.55.174-1.067.489-1.452.297-.365.69-.567 1.1-.567.876 0 1.59.904 1.59 2.019 0 .185.151.337.336.337a.338.338 0 0 0 .337-.337C6.446 1.86 5.43.652 4.183.652c-.617 0-1.193.29-1.623.815-.412.505-.64 1.17-.64 1.878 0 .399.073.783.21 1.137L.908 5.555a1.42 1.42 0 0 0-.483 1.064v6.047c0 .933.758 1.69 1.69 1.69h4.141c.933 0 1.69-.757 1.69-1.69V6.62a1.42 1.42 0 0 0-.482-1.064Zm-.191 7.111c0 .562-.455 1.017-1.017 1.017H2.111a1.016 1.016 0 0 1-1.016-1.017V6.62c0-.214.093-.419.253-.559l1.109-.971.1.134c.43.525 1.006.815 1.624.815a.338.338 0 0 0 .336-.337.338.338 0 0 0-.336-.337c-.413 0-.803-.202-1.101-.567a3.15 3.15 0 0 1-.115-.155l1.134-.994a.12.12 0 0 1 .082-.03.12.12 0 0 1 .081.03L7.016 6.06c.16.14.253.345.253.559v6.047h.003Z" fill="#555"></path><path d="M4.554 9.171h-.742a.476.476 0 0 1-.474-.474c0-.261.213-.475.474-.475h1.306a.338.338 0 0 0 .337-.337.338.338 0 0 0-.337-.337H4.52V7.11a.338.338 0 0 0-.337-.337.338.338 0 0 0-.337.337v.438h-.034a1.15 1.15 0 0 0-1.148 1.149 1.15 1.15 0 0 0 1.148 1.148h.742a.476.476 0 0 1 0 .949H3.223a.338.338 0 0 0-.337.337c0 .185.151.337.337.337h.623v.446c0 .186.152.337.337.337a.338.338 0 0 0 .337-.337v-.446h.048a1.15 1.15 0 0 0 1.134-1.148A1.15 1.15 0 0 0 4.554 9.17Z" fill="#555"></path></svg>
                </div>
                <h5>Price starting from: QAR{props.profile.startingPrice}</h5>
            </li>
        </ul>
        <div className='doctor-profile-buttons'>
            <button className='doctor-profile-button view-profile'>
                <h5>View Profile</h5>
            </button>
            <button className='doctor-profile-button book-now'>
            <svg width="18" height="18" fill="none" xmlns="http://www.w3.org/2000/svg" class="sc-3b47186d-26 iErXiB"><path d="M6.896 8.154H5.44V9.61h1.456V8.154Zm2.912 0H8.352V9.61h1.456V8.154Zm2.912 0h-1.456V9.61h1.456V8.154Zm1.456-5.096h-.728V1.602h-1.456v1.456H6.168V1.602H4.71v1.456h-.728a1.45 1.45 0 0 0-1.448 1.456l-.008 10.192c0 .801.648 1.457 1.456 1.457h10.193c.801 0 1.456-.656 1.456-1.457V4.514c0-.801-.655-1.456-1.456-1.456Zm0 11.648H3.983V6.698h10.193v8.008Z" fill="#fff"></path></svg>
                <h5>Book Now</h5>
            </button>
        </div>
    </div>
  )
}
